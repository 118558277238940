var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("爱心点燃希望 真情传递温暖 ——")]),_c('h3',[_vm._v("新云网向花垣县双龙镇的十八洞小学进行捐赠")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails4')}}},[_vm._v("上一条：用强有力的奖励政策 加速科技成果转化落地")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails6')}}},[_vm._v("下一条：我是接班人”网络大课堂反响强烈！近700万人次观看")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2020-05-30 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 种下一粒种子，收获一片生机；献出一片爱心，托起一份希望。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 2020年5月，湖南新云网科技有限公司仲夏送暖、与爱同行，联合长沙市育英小学，举办了爱心公益捐赠活动。向湖南湘西土家族苗族自治州花垣县双龙镇的十八洞小学捐赠了书包、笔记本等一批学习用品。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 十八洞小学位于素有花垣“南大门”之称的原排碧乡西南部，学校绿荫环绕，青山环抱。在六一儿童节即将来临之际，为了让孩子们快乐的度过自己美好的节日，感受到爱的力量，新云网积极履行社会责任和担当，助力教育扶贫。用实际行动传递温暖，献出爱心，献出自己微薄的力量，用一份“爱心礼包”表达对孩子们成长的关爱和学习的问候。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 作为一家深耕信息技术领域的高新技术企业，新云网始终秉持人文关怀，在教育方面的系列产品，致力于用技术服务教育领域，用互联网科技赋能教育发展，做有温度的教育。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news5/news1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 十八洞小学也是育英小学的对口支援学校。“一直记得‘我是接班人’湖南省网络大课堂第一课下课后，老师安排放学，十八洞的孩子们小板凳都已经搁在桌上，但依然都久久盯着屏幕不愿回家的模样。最近因为拍摄出旗，蒲老师砍来竹竿做旗杆的举动深深触动了我。这个六一，我们一起为十八洞小学的孩子们稍去节日礼物。”育英小学“我是接班人”湖南省网络大课堂总班主任郭晓芳老师深情的说。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news5/news2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin":"20px 0 40px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 爱心点燃希望，真情传递温暖。新云网这一善举，体现了他们对教育事业发展的关注、关心和责任。希望这次捐助的火种能够在全社会中传续下去，积少成多，聚沙成塔，为农村偏远地区的教育贡献力量。因为有爱，不论是始终坚守在十八洞小学的蒲老师、育英小学，还是爱心捐赠的企业，我们在一起，以我们的点滴之爱汇聚成爱心的暖流，滋润贫困者的心灵。 ")])])
}]

export { render, staticRenderFns }